
.simflowpool-info{
  background-color: #fff;
  border-bottom: 3px #f5f5f5 solid ;
  padding-top: 45px;
  div{
    height: 36px;
    span{
      width: calc(100%/2);
      display: inline-block;
      line-height: 36px;
      font-size: 12px;
      color: #333;
      margin: 0 !important; 
    }
  }
} 
.simDatasList{
  border-bottom: 1px solid #ccc;
  background: #fff;
  padding:5px 0 5px 15px;
  div{
    // height: 24px;
    span{
      display: inline-block;
      line-height: 30px;
      font-size: 12px;
      color: #333;
      margin: 0 !important; 
    }
  }
  .status_box{
    span{
      width: calc(100%/2);
      display: inline-block;
      line-height: 24px;
      font-size: 12px;
      color: #666;
      margin: 0 !important; 
    }
  }
}