.groupList {
    .header-sticky{
        img{
            width: 25px;
        }
    }
    &>.sub-container .footer {
        height: 45px;
        width: 100%;
        background: #fff;
        position: fixed;
        bottom: 0;
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        align-items: center;
        z-index: 1;
        box-shadow: 1px -3px 19px #d4d4d4;

        &>span {
            color: #108EE9;
        }
    }
}