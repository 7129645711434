.login {
    .login-top {
        margin: 50px 0;
        margin-bottom: 20px;
        img {
            width: 3.5rem
        }
    }

    .middle {
        .middle-info {
            border-bottom: 1px solid #ccc;
            margin-bottom: 10px;
        }
    }

    .bottom {
        margin-top: 50px
    }
    .register {
        margin-top: 30px;
    }
}