.addgroup {
    .am-list-footer{
        color: red;
    }
    
}
.am-list-content{
    display: flex;
}
.title_name{
    height: 30px;
    line-height: 30px;
    padding-left: 59px;
    display: flex;
    justify-content: space-between;
    background: #fff;
    div{
        flex: 1;
        font-size: 13px;
        color: rgba($color: #000000, $alpha: 0.8);
    }
}