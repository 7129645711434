.main-permission-list {
    height: 100%;
    .permission-list {
        width: 100%;
        height: 100%;
        background: #fff;
        box-sizing: border-box;
        padding:0px 10px;
    }
    .am-list-line-multiple .am-list-brief{
        font-size: 12px;
    }
}