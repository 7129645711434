.menu{
    background: #F8F8F8;
    height: 100%;
    padding-bottom: 50px;
    .list-item{
        .title{
            padding: 10px;
        }
        .am-grid-item-content{
            img{
                width: 30px;
            }
            .am-grid-text{
                font-size: 16px !important;
            }
        }
    }
    .am-card-header-content{
        img{
            width: 40px;
        }
    }
}