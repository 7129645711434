html,
body {
    width: 100%;
    height: 100%;
    background-color: #f5f5f5 !important;

    div {
        font-size: 16px;
    }

    .App,
    #root {
        width: 100%;
        height: 100%;
        overflow: hidden;
        overflow-y: scroll;

        .am-navbar {
            background-color: #061A3F;
        }

        .header-sticky {
            position: fixed;
            width: 100%;
            z-index: 1000;
        }

        .header-navbar {
            top: 0;
        }

        .header-notice {
            bottom: 0;
        }

        .main {
            .container {
                // height: 100%;
            }
        }

        .sub-container {
            height: 100%;
            padding: 45px 0 0;
            box-sizing: border-box;

            .footer {
                height: 45px;
                width: 100%;
                background: #fff;
                position: fixed;
                bottom: 0;
                padding: 0 20px;
                display: flex;
                justify-content: space-between;
                box-sizing: border-box;
                align-items: center;
                z-index: 10;
                box-shadow: 1px -3px 19px #d4d4d4;

                &>span {
                    color: #108EE9;
                }
            }
        }

        .am-list-item .am-list-line .am-list-content {
            font-size: 16px;
        }

    }
}