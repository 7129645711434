.bingWeiXin{
    text-align: center;

    .sub-title {
        color: #888;
        font-size: 21px;
        padding: 18px 0;
    }

    .spe {
        width: 60px;
        height: 60px;
    }
}
