.kakaing {
    width: 100%;
    height: 100%;
}

.cententext {
    width: 100%;
    height: 500px;
    // background: skyblue;
}

.saoyisao {
    text-align: center;
}

.saoyisao img {
    height: 200px;
    width: 200px;
}