.adduser{
    position: relative;
}
.amap-icon img{
    width: 30px;
}
.content-window-card {
    position: relative;
    box-shadow: none;
    bottom: 0;
    left: 0;
    width: auto;
    padding: 0;
}

.content-window-card p {
    height: 2rem;
}

.custom-info {
    border: solid 1px silver;
    position: relative;
    width:200px;
}
.info-bottom{
    left:5px;
}

div.info-top {
    position: relative;
    background: none repeat scroll 0 0 #F9F9F9;
    border-bottom: 1px solid #CCC;
    border-radius: 5px 5px 0 0;
}

div.info-top div {
    display: inline-block;
    color: #333333;
    font-size: 14px;
    font-weight: bold;
    line-height: 31px;
    padding: 0 10px;
}

div.info-top img {
    position: absolute;
    top: 10px;
    right: 10px;
    transition-duration: 0.25s;
}

div.info-top img:hover {
    box-shadow: 0px 0px 5px #000;
}

div.info-middle {
    font-size: 12px;
    padding: 10px 6px;
    line-height: 20px;
}

div.info-bottom {
    height: 0px;
    width: 100%;
    clear: both;
    text-align: center;
}

div.info-bottom img {
    position: relative;
    z-index: 104;
}

span {
    margin-left: 5px;
    font-size: 11px;
}

.info-middle img {
    float: left;
    margin-right: 6px;
}
.my-drawer {
    position: relative;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 999;
  }
  .my-drawer .am-drawer-sidebar {
    background-color: #fff;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    margin-top: 45px;
  }
  .my-drawer .am-drawer-sidebar .am-list {
    width: 300px;
    padding: 0;
  }
  .retrogress{
      color:blue;
      font-size: 14px;
      position: absolute;
      top: 64px;
      right: 14px;
  }
  .am-checkbox-inner {
    border-radius: 0 !important;
}
.footer{
    line-height: 35px;
    text-align: center;
    font-size: 14px;
    color: #666;
    margin-top: 5px;
}
.am-drawer-sidebar{
    width: 85%;
}