body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,body{width:100%;height:100%;background-color:#f5f5f5 !important}html div,body div{font-size:16px}html .App,html #root,body .App,body #root{width:100%;height:100%;overflow:hidden;overflow-y:scroll}html .App .am-navbar,html #root .am-navbar,body .App .am-navbar,body #root .am-navbar{background-color:#061A3F}html .App .header-sticky,html #root .header-sticky,body .App .header-sticky,body #root .header-sticky{position:fixed;width:100%;z-index:1000}html .App .header-navbar,html #root .header-navbar,body .App .header-navbar,body #root .header-navbar{top:0}html .App .header-notice,html #root .header-notice,body .App .header-notice,body #root .header-notice{bottom:0}html .App .sub-container,html #root .sub-container,body .App .sub-container,body #root .sub-container{height:100%;padding:45px 0 0;box-sizing:border-box}html .App .sub-container .footer,html #root .sub-container .footer,body .App .sub-container .footer,body #root .sub-container .footer{height:45px;width:100%;background:#fff;position:fixed;bottom:0;padding:0 20px;display:flex;justify-content:space-between;box-sizing:border-box;align-items:center;z-index:10;box-shadow:1px -3px 19px #d4d4d4}html .App .sub-container .footer>span,html #root .sub-container .footer>span,body .App .sub-container .footer>span,body #root .sub-container .footer>span{color:#108EE9}html .App .am-list-item .am-list-line .am-list-content,html #root .am-list-item .am-list-line .am-list-content,body .App .am-list-item .am-list-line .am-list-content,body #root .am-list-item .am-list-line .am-list-content{font-size:16px}

.login .login-top{margin:50px 0;margin-bottom:20px}.login .login-top img{width:3.5rem}.login .middle .middle-info{border-bottom:1px solid #ccc;margin-bottom:10px}.login .bottom{margin-top:50px}.login .register{margin-top:30px}

.menu{background:#F8F8F8;height:100%;padding-bottom:50px}.menu .list-item .title{padding:10px}.menu .list-item .am-grid-item-content img{width:30px}.menu .list-item .am-grid-item-content .am-grid-text{font-size:16px !important}.menu .am-card-header-content img{width:40px}

.user-list .item-brief{color:#888;font-size:14px}

.adduser{position:relative}.adduser .am-list-footer,.adduser .red-star{color:red}.adduser .hide-input input{display:none}

.currencyLedgerlist{height:100%}.currencyLedgerlist .currencyLedger-sidebar{width:100%;height:100%;background:#fff;box-sizing:border-box;padding:45px 10px}

.adduser{position:relative}.adduser .am-list-footer,.adduser .red-star{color:red}.adduser .hide-input input{display:none}.am-list-item .am-input-label.am-input-label-5{width:100px}.radioBox{width:100%;height:40px;background:#fff;line-height:40px;text-align:center}.radioBox input{margin-left:15px}.input1{margin-left:104px}.iccidBox{position:relative}.iccidBox .addDom{position:absolute;width:20px;height:20px;border-radius:50%;background:#666;z-index:999;text-align:center;line-height:20px;color:#fff;font-size:14px;right:5px;bottom:35px}.iccidBox .delDom{position:absolute;width:20px;height:20px;border-radius:50%;background:red;z-index:999;text-align:center;line-height:20px;color:#fff;font-size:14px;right:32px;bottom:35px}

.addAdmin .red-star{color:red}

.adminList .sub-container .am-list-brief{font-size:12px}

.billExchange .am-list-footer{color:red}

.addAdmin .am-list-item .am-list-line .am-list-content{font-size:16px}

.main-permission-list{height:100%}.main-permission-list .permission-list{width:100%;height:100%;background:#fff;box-sizing:border-box;padding:0px 10px}.main-permission-list .am-list-line-multiple .am-list-brief{font-size:12px}

.billlist{height:100%}.billlist .bill-sidebar{width:100%;height:100%;background:#fff;box-sizing:border-box;padding:45px 10px}

.billMessage{height:100%}.billMessage .bill-sidebar{width:100%;height:100%;background:#fff;box-sizing:border-box;padding:45px 10px}.billMessage .am-list-line-multiple .am-list-brief{font-size:12px}

.addgroup .am-list-footer{color:red}

.groupList .header-sticky img{width:25px}.groupList>.sub-container .footer{height:45px;width:100%;background:#fff;position:fixed;bottom:0;padding:0 20px;display:flex;justify-content:space-between;box-sizing:border-box;align-items:center;z-index:1;box-shadow:1px -3px 19px #d4d4d4}.groupList>.sub-container .footer>span{color:#108EE9}

.am-list-footer,.red-star{color:red}

.bingWeiXin{text-align:center}.bingWeiXin .sub-title{color:#888;font-size:21px;padding:18px 0}.bingWeiXin .spe{width:60px;height:60px}

.simInfo .red-star{color:red}

.simflowpool-info{background-color:#fff;border-bottom:3px #f5f5f5 solid;padding-top:45px}.simflowpool-info div{height:36px}.simflowpool-info div span{width:calc(100%/2);display:inline-block;line-height:36px;font-size:12px;color:#333;margin:0 !important}.simDatasList{border-bottom:1px solid #ccc;background:#fff;padding:5px 0 5px 15px}.simDatasList div span{display:inline-block;line-height:30px;font-size:12px;color:#333;margin:0 !important}.simDatasList .status_box span{width:calc(100%/2);display:inline-block;line-height:24px;font-size:12px;color:#666;margin:0 !important}

.adduser{position:relative}.amap-icon img{width:30px}.content-window-card{position:relative;box-shadow:none;bottom:0;left:0;width:auto;padding:0}.content-window-card p{height:2rem}.custom-info{border:solid 1px silver;position:relative;width:200px}.info-bottom{left:5px}div.info-top{position:relative;background:none repeat scroll 0 0 #F9F9F9;border-bottom:1px solid #CCC;border-radius:5px 5px 0 0}div.info-top div{display:inline-block;color:#333333;font-size:14px;font-weight:bold;line-height:31px;padding:0 10px}div.info-top img{position:absolute;top:10px;right:10px;transition-duration:0.25s}div.info-top img:hover{box-shadow:0px 0px 5px #000}div.info-middle{font-size:12px;padding:10px 6px;line-height:20px}div.info-bottom{height:0px;width:100%;clear:both;text-align:center}div.info-bottom img{position:relative;z-index:104}span{margin-left:5px;font-size:11px}.info-middle img{float:left;margin-right:6px}.my-drawer{position:relative;overflow:auto;-webkit-overflow-scrolling:touch;z-index:999}.my-drawer .am-drawer-sidebar{background-color:#fff;overflow:auto;-webkit-overflow-scrolling:touch;margin-top:45px}.my-drawer .am-drawer-sidebar .am-list{width:300px;padding:0}.retrogress{color:blue;font-size:14px;position:absolute;top:64px;right:14px}.am-checkbox-inner{border-radius:0 !important}.footer{line-height:35px;text-align:center;font-size:14px;color:#666;margin-top:5px}.am-drawer-sidebar{width:85%}

.user-list .item-brief{color:#888;font-size:14px}

.adduser{position:relative}.adduser .am-list-footer,.adduser .red-star{color:red}.adduser .hide-input input{display:none}

.am-list-footer,.red-star{color:red}

.adduser{position:relative}.amap-icon img{width:30px}.content-window-card{position:relative;box-shadow:none;bottom:0;left:0;width:auto;padding:0}.content-window-card p{height:2rem}.custom-info{border:solid 1px silver;position:relative;width:200px}.info-bottom{left:5px}div.info-top{position:relative;background:none repeat scroll 0 0 #F9F9F9;border-bottom:1px solid #CCC;border-radius:5px 5px 0 0}div.info-top div{display:inline-block;color:#333333;font-size:14px;font-weight:bold;line-height:31px;padding:0 10px}div.info-top img{position:absolute;top:10px;right:10px;transition-duration:0.25s}div.info-top img:hover{box-shadow:0px 0px 5px #000}div.info-middle{font-size:12px;padding:10px 6px;line-height:20px}div.info-bottom{height:0px;width:100%;clear:both;text-align:center}div.info-bottom img{position:relative;z-index:104}span{margin-left:5px;font-size:11px}.info-middle img{float:left;margin-right:6px}.my-drawer{position:relative;overflow:auto;-webkit-overflow-scrolling:touch;z-index:999}.my-drawer .am-drawer-sidebar{background-color:#fff;overflow:auto;-webkit-overflow-scrolling:touch;margin-top:45px}.my-drawer .am-drawer-sidebar .am-list{width:300px;padding:0}.retrogress{color:blue;font-size:14px;position:absolute;top:64px;right:14px}.am-checkbox-inner{border-radius:0 !important}.footer{line-height:35px;text-align:center;font-size:14px;color:#666;margin-top:5px}.am-drawer-sidebar{width:85%}

.groupList .header-sticky img{width:25px}.groupList>.sub-container .footer{height:45px;width:100%;background:#fff;position:fixed;bottom:0;padding:0 20px;display:flex;justify-content:space-between;box-sizing:border-box;align-items:center;z-index:1;box-shadow:1px -3px 19px #d4d4d4}.groupList>.sub-container .footer>span{color:#108EE9}

.addgroup .am-list-footer{color:red}.am-list-content{display:flex}.title_name{height:30px;line-height:30px;padding-left:59px;display:flex;justify-content:space-between;background:#fff}.title_name div{flex:1 1;font-size:13px;color:rgba(0,0,0,0.8)}

.adminList .sub-container .am-list-brief{font-size:12px}

.addAdmin .red-star{color:red}

.addAdmin .am-list-item .am-list-line .am-list-content{font-size:16px}

.main-permission-list{height:100%}.main-permission-list .permission-list{width:100%;height:100%;background:#fff;box-sizing:border-box;padding:0px 10px}.main-permission-list .am-list-line-multiple .am-list-brief{font-size:12px}

.kakaing{width:100%;height:100%}.cententext{width:100%;height:500px}.saoyisao{text-align:center}.saoyisao img{height:200px;width:200px}

.placeholder{background-color:#ebebef;color:#bbb;text-align:center;height:30px;line-height:30px;width:100%}

.uhss-list{width:100%;height:100%;overflow:hidden;overflow-y:scroll}.uhss-list .list-er{color:#888;font-size:14px;position:fixed;width:100%;z-index:1000}.uhss-list .hide-input input{display:none}.my-radio .am-radio{padding:2.5px;border:1px solid #ccc;border-radius:50%;margin-right:5px}

