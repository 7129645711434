.uhss-list {
    width: 100%;
        height: 100%;
        overflow: hidden;
        overflow-y: scroll;
    .list-er {
        color: #888;
        font-size: 14px;
        position: fixed;
        width: 100%;
        z-index: 1000;
    }
    .hide-input{
        input{
            display: none;
        }
    }
}

.my-radio .am-radio {
    padding: 2.5px;
    border: 1px solid #ccc;
    border-radius: 50%;
    margin-right: 5px;
}