.adduser{
    position: relative;
    .am-list-footer,.red-star{
        color: red;
    }
    
    .hide-input{
        input{
            display: none;
        }
    }
}