.adduser{
    position: relative;
    .am-list-footer,.red-star{
        color: red;
    }
    
    .hide-input{
        input{
            display: none;
        }
    }
}
.am-list-item .am-input-label.am-input-label-5 {
    width: 100px;
}
.radioBox{
    width: 100%;
    height: 40px;
    background: #fff;
    line-height: 40px;
    text-align: center;
}
.radioBox input{
    margin-left: 15px;
}
.input1{
    margin-left: 104px;
}
.iccidBox{
    position: relative;
    .addDom{
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #666;
        z-index: 999;
        text-align: center;
        line-height: 20px;
        color: #fff;
        font-size: 14px;
        right: 5px;
        bottom: 35px;
    }
    .delDom{
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: red;
        z-index: 999;
        text-align: center;
        line-height: 20px;
        color: #fff;
        font-size: 14px;
        right: 32px;
        bottom: 35px;
    }
}